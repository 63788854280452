<template>
  <div class="reviewpracticeTwoStep">
    <div id="gjz" v-show="gjzShow" :style="'top:' + top + 'px;left:' + left + 'px'">{{ gjz }}</div>
    <div class="leftnav">
      <step-secont :active="2" />
    </div>
    <main>
      <div class="boxLeft">
        <div class="background">
          <div class="imgWrap">
            <img :src="details.wgjztpdz" alt="">
            <div class="textInput" v-for="(item, index) in textlist" :key="index" :style="[{top: (item.dbjwz ? item.dbjwz + '%' : 0)}, {left: (item.zbjwz ? item.zbjwz + '%' : 0)}]">{{ answer[index] ? answer[index] : '&nbsp;&nbsp;' }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p> {{ details.kwmc.substring(0,9) }} </p>
          <p> {{ details.kwmc.substring(9) }} </p>
        </div>
        <div class="bigbox" v-show="yworfy">
          <div class="box" id="box">
            <p>请把下面关键词拖动到对应的输入框里</p>
            <div class="currentDom" v-for="(item, index) in textlist" :key="index">
              <li class="li" @touchstart="touchstart" :style="answer.indexOf(item.gjz) !== -1 ? 'color: #409eff' : ''">{{index+1+'、'+item.gjz}}</li>
            </div>
          </div>
        </div>
        <div class="bigbox" v-show="!yworfy">
          <div class="box" id="box">
            <li class="currentDom" v-for="(item, index) in textlist1" :key="index" v-text="item.jznr"></li>
          </div>
        </div>
        <div class="right-footer" id="footer">
          <div class="text">
            <p>提示</p>
            <p>点击下方查看全文</p>
          </div>
          <div class="bottom">
            <div class="div">
              <img src="../../../assets/student/bolang.png" alt="">
              <img src="../../../assets/student/bolang.png" alt="">
              <div class="seetext" :class="yworfy ? 'seetextfy' : 'seetextyw'" @click="translateBtn">
                <img src="../../../assets/student/wordysxbtn.png" alt="">
                <p>{{yworfy ? '查看原文' : '关闭原文'}}</p>
              </div>
            </div>
            <div @click="next">提交</div>
          </div>
        </div>
      </div>
    </main>
    <Header :header_data="header_data" :noClicking="true" />
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { getCurrentInstance, nextTick, onBeforeUnmount } from 'vue'
import { ElMessage } from "element-plus"
import { useStore } from 'vuex'
import { getEnglishText, getTextKeys, getTextContent } from '@/api/student'
import StepSecont from '../components/stepSecont.vue';
export default {
  components: {
    Header,
    StepSecont,
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties
    const data = reactive({
      yworfy: true, // 原文，或者翻译
      details: { kwmc: '' },
      header_data: [],
      textlist: [],
      textlist1: [],
      liDoms: [],
      gjz: '',
      top: 0,
      left: 0,
      gjzShow: false,
      answer: []
    })
    onMounted(() => {
      // 获取课文发音
      getEnglishText({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          data.details = res.result
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      // 获取课文关键字
      getTextKeys({ kwid: Number(that.$route.query.kwid), kwnrid: 0 }).then(res => {
        if(res.success) {
          data.textlist = res.result.sort(randomsort)
          nextTick(() => {
            // 禁止复制粘贴
            document.oncontextmenu=new Function("event.returnValue=false");
            document.onselectstart=new Function("event.returnValue=false");
            setTimeout(() => {
              let oDom = document.querySelector('body')
              // PC
              oDom.addEventListener('mousedown', bodyMouseDown, false)
              oDom.addEventListener('mouseup', bodyMouseUp, false)
              oDom.addEventListener('mousemove', bodyMouseMove, false)
              // 移动
              oDom.addEventListener('touchstart', bodyTouchStart, false)
              oDom.addEventListener('touchend', bodyTouchEnd, false)
              oDom.addEventListener('touchmove', bodyTouchMove, false)
              data.liDoms = document.getElementsByClassName('li')
              for (let i = 0; i < data.liDoms.length; i++) {
                data.liDoms[i].addEventListener('mousedown', liMouseDown, false)
              }
              let textInput = document.getElementsByClassName('textInput')
              for (let i = 0; i < textInput.length; i++) {
                data.textlist[i].top = textInput[i].getBoundingClientRect().top
                data.textlist[i].left = textInput[i].getBoundingClientRect().left
              }
            }, 500)
          })
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      // 获取课文内容
      getTextContent({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          if(res.result.length) {
            data.textlist1 = res.result
          }
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    // 查看翻译--查看原文
    function translateBtn() {
      data.yworfy = !data.yworfy
    }
    // 右侧点击选中关键字
    const liMouseDown = (e) => {
      data.gjz = e.target.innerHTML.slice(e.target.innerHTML.indexOf('、')+1)
      data.gjzShow = true
    }
    const touchstart = (e) => {
      data.gjz = e.target.innerHTML.slice(e.target.innerHTML.indexOf('、')+1)
      data.gjzShow = true
    }
    // （全屏）鼠标按下
    const bodyMouseDown = (e) => {
      data.left = e.clientX - 30
      data.top = e.clientY - 20
    }
    const bodyTouchStart = (e) => {
      data.left = e.touches[0].pageX - 30
      data.top = e.touches[0].pageY - 20
    }
    // （全屏）鼠标移动
    const bodyMouseMove = (e) => {
      data.left = e.clientX - 30
      data.top = e.clientY - 20
    }
    const bodyTouchMove = (e) => {
      data.left = e.touches[0].pageX - 30
      data.top = e.touches[0].pageY - 20
    }
    //（全屏）鼠标抬起
    const bodyMouseUp = (e) => {
      selectAnswer(e.clientY, e.clientX)
      data.gjzShow = false
      data.gjz = ''
    }
    const bodyTouchEnd = (e) => {
      selectAnswer(e.changedTouches[0].pageY, e.changedTouches[0].pageX)
      data.gjzShow = false
      data.gjz = ''
    }
    onBeforeUnmount(() => {
      let oDom = document.querySelector('body')
      oDom.removeEventListener('mousedown', bodyMouseDown, false)
      oDom.removeEventListener('mouseup', bodyMouseUp, false)
      oDom.removeEventListener('mousemove', bodyMouseMove, false)
      oDom.removeEventListener('touchstart', bodyTouchStart, false)
      oDom.removeEventListener('touchend', bodyTouchEnd, false)
      oDom.removeEventListener('touchmove', bodyTouchMove, false)
    })
    // 根据位置判断填入哪里
    const selectAnswer = (top, left) => {
      data.textlist.forEach((ele, index) => {
        if (Math.abs(top-ele.top) + Math.abs(left-ele.left) < 60) {
          if(data.answer[index]) {
            ElMessage({
              message: '此位置已填入正确答案~',
              type: 'warning'
            })
          } else if (!data.gjz) {
            ElMessage({
              message: '请拖动右侧关键字~',
              type: 'warning'
            })
          } else if (data.textlist[index].gjz === data.gjz) {
            data.answer[index] = data.gjz
            ElMessage({
              message: '答对了~',
              type: 'success'
            })
          } else {
            ElMessage({
              message: '很遗憾答错了~',
              type: 'error'
            })
          }
          return false
        }
      })
    }
    // 下一步
    const next = () => {
      if (data.answer.length < data.textlist.length) {
        ElMessage({
          message: '还未答完无法进行下一步~',
          type: 'error'
        })
        return false
      }
      for (let i = 0; i < data.answer.length; i++) {
        if (!data.answer[i]) {
          ElMessage({
            message: '还未答完无法进行下一步~',
            type: 'error'
          })
          return false
        }
      }
      that.$router.replace({ name: 'reviewsubmitTest', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid} })
    }
    function randomsort(a, b) {
      return Math.random()>.5 ? -1 : 1;
    }
    return {
      ...toRefs(data),
      translateBtn,
      liMouseDown,
      touchstart,
      bodyMouseDown,
      bodyMouseUp,
      bodyMouseMove,
      bodyTouchStart,
      bodyTouchMove,
      bodyTouchEnd,
      selectAnswer,
      next,
      randomsort
    };
  },
};
</script>
<style lang="scss" scoped>
.reviewpracticeTwoStep {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  #gjz{
    display: inline-block;
    z-index: 99;
    font-size: 22px;
    color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #2CA2F5;
  }
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: .2vw .2vw .2vw 0;
    .boxLeft {
      width: 71.2vw;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding: 1vw 0 1vw 2vw;
      position: relative;
      .background {
        position: absolute;
        top: -1vw;
        left: 3.5vw;
        right: 0;
        bottom: -1vw;
        .imgWrap{
          height: 100%;
          position: fixed;
          left: 40%;
          transform: translateX(-50%);
        }
        img {
          height: 100%;
        }
      }
    }
    .right {
      height: 100%;
      width: 23.3vw;
      // border: 6px solid #2CA2F5;
      border-radius: 3.125vw;
      box-sizing: border-box;
      padding-top: 1vw;
      position: relative;
      float: right;
      .title {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;
        p {
          font-family: 'Times New Roman', Times, serif;
        }
      }
      .bigbox {
        position: absolute;
        top: 15%;
        bottom: 30%;
        left: 0;
        right: -.18vw;
        overflow: hidden;
        .box {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding-left: 1.5vw;
          overflow: auto;
          cursor: pointer;
          p:nth-child(1) {
            padding-left: .7vw;
            font-size: 1.0416vw;
            font-weight: 600;
          }
          li {
            color: #333;
            font-size: 1.3vw;
            line-height: 2.5vw;
            font-family: 'Times New Roman', Times, serif;
          }
          .my-input {
            color: #66757f;
            display: inline-block;
            height: 22px;
            min-width: 20px;
            max-width: 300px;
            height: 2.8125vw;
            position:fixed;
            border: 4px solid #2167B2;
            border-radius:6px;
          }
        }
      }
      .right-footer {
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        // border-top: 5px solid #2CA2F5;
        .text {
          padding-top: 5%;
          text-align: center;
          p {
            font-size: 1.2vw;
          }
        }
        .bottom {
          position: absolute;
          bottom: 5%;
          width: 92%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: space-around;
          .div:nth-child(1) {
            width: 12.7604vw;
            height: 3.75vw;
            background: url("../../../assets/student/writebtn.png") no-repeat;
            background-size: 100% 100%;
            position: relative;
            img{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            img:nth-child(1) {
              left: 10%;
            }
            img:nth-child(2) {
              right: 10%;
            }
            .seetext {
              width: 9.218vw;
              height: 3.65vw;
              position: absolute;
              transition: 0.5s;
              cursor: pointer;
              img {
                display: block;
                width: 100%;
                height: 100%;
                left: 0;
              }
              p {
                position: absolute;
                width: 100%;
                text-align: center;
                color: #fff;
                text-align: center;
                line-height: 3.65vw;
                font-size: 1.2583vw;
              }
            }
            .seetextfy {
              left: 0;
            }
            .seetextyw {
              left: 30%;
            }
          }
          div:nth-child(2) {
            width: 6.25vw;
            height: 3.33333vw;
            background: url('../../../assets/student/wordysxbtn.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 1.258333vw;
            text-align: center;
            line-height: 3.333vw;
            cursor: pointer;
            border-radius: 60px;
          }
        }
      }
    }
  }
  .active {
    color: #578EFF !important;
    font-size: 1.5vw !important;
  }
  .activeNext {
    color: #000000 !important;
    font-size: 1.4vw !important;
  }
  ::-webkit-scrollbar {
    width: .6vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
  // 设置input框宽度
  .textInput {
    white-space: nowrap;
    min-width: 3vw;
    height: 30px;
    position: absolute;
    border: 1px solid #2167B2;
    border-radius:6px;
    padding: 0 6px;
    background: #fff;
    line-height: 25px;
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
  }
}
</style>